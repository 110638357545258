import React, {Component} from 'react';
import {Navigate} from 'react-router-dom';
import ScratchCard from '../utils/ScratchCard';
import { confirmAlert } from '../utils/react-confirm-alert';
import scratchMaskImage from '../../styles/images/scratcher-top-image2.jpg';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import AuthService from '../utils/AuthService';
import Confetti from 'react-confetti';
import posed, { PoseGroup } from 'react-pose';
import fileDownload from 'js-file-download';
import {convertTimeStampToHumanReadable, validateEmail} from "../utils/HelpfulFunction";
import '../../styles/css/Scratcher.css';
import '../../styles/css/Home.css';
import '../../styles/css/ConfirmAlertCustom.css';
import PulseLoader from "react-spinners/PulseLoader";
import {base} from "../../base";
import { Textfit } from 'react-textfit';
const Axios = require('axios');

const ScratchContainer = posed.div({
  enter: {
    y: 1000,
    opacity: 1,
    delay: 360000,
    transition: {
      y: { type: 'spring', stiffness: 1000, damping: 15 },
      default: { duration: 3000 }
    }
  }
});

const Box = posed.div({
  visible: { opacity: 1 },
  hidden: { opacity: 0 }
});

class Home extends Component {
    constructor(props) {
        super(props);
        this.Auth = new AuthService();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleImageLoaded = this.handleImageLoaded.bind(this);
        this.handleImageErrored = this.handleImageErrored.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleSupport = this.toggleSupport.bind(this);
        this.toggleInfoAndRules = this.toggleInfoAndRules.bind(this);
        this.state = {
            scratcherList: [],
            showNext: false,
            isVisible: false,
            winner: false,
            scratchState: 0,
            loading:true,
            gameId: null,
            startConfetti: false,
            currentScratcherLocation: 0,
            modal:false,
            downloadModal: false,
            areYouSureModal: false,
            sendPrizeInfoModal: false,
            letsPlayDisabled: false,
            currentGame: {},
            userEmail: props.appStorage.getItem('userEmail') || false
        };
    }

    handleImageLoaded() {
      this.setState({
        loading: false,
        isVisible: true,
      });
    }

    handleImageErrored() {
      console.log("IMAGE FAILED TO LOAD");
      this.setState({ loading: false });
    }

    wrapText(context, text, x, y, maxWidth, lineHeight) {
        const words = text.split(' ');
        let line = '';
        let count = 1;

        for(let n = 0; n < words.length; n++) {
            const testLine = line + words[n] + ' ';
            const metrics = context.measureText(testLine);
            const testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                context.fillText(line, x, y);
                line = words[n] + ' ';
                y += lineHeight;
                count++;
            }
            else {
                line = testLine;
            }
        }
        context.fillText(line, x, y);
        return count*lineHeight;
    }

    fitTextOnCanvas(text,fontFace,yPosition,ctx, maxWidthAllowed){

        // start with a large font size
        let fontsize=45;

        // lower the font size until the text fits the canvas
        do{
            fontsize--;
            ctx.font=fontsize+"px "+fontFace;
        }while(ctx.measureText(text).width> maxWidthAllowed - 20)

        // draw the text
        ctx.fillText(text,maxWidthAllowed/2,yPosition);
    }

    componentDidMount(){
      const subValue = this.state.value;
      if(!validateEmail(subValue)){
        this.setState({
          value: ""
        })
      }
      this.rewardsListRef = base.listenToCollection(`rewardsList`, {
          context: this,
          query: (ref) => ref.where('isWinning', '==', false),
          then(data) {
              for(const i in data){
                  const reward = data[i];
                  this.setState({
                      losingReward: reward
                  })
              }
          },
          onFailure(err){
              console.log(err)
          }
      });

      this.currentGameStartTimeRef = base.listenToCollection('gamesList', {
          context: this,
          query: (ref) => ref.where('currentGame', '==', true),
          then(data){
              for(const i in data){
                  const game = data[i];
                  this.setState({
                      currentGame: game
                  })
              }
          },
          onFailure(err){
              console.log(err);
          }
      })

      // var element = document.getElementById('mainContent')
      // var transfromString = ("translate(0%, 0%)");
      // element.style.width = "100%"
      // element.style.webkitTransform = transfromString;
      // element.style.MozTransform = transfromString;
      // element.style.msTransform = transfromString;
      // element.style.OTransform = transfromString;
      // element.style.transform = transfromString;
        this.props.loadPage();
    }

    componentWillUnmount() {
        base.removeBinding(this.rewardsListRef);
        base.removeBinding(this.currentGameStartTimeRef);
    }

    populateWithTickets(rewardWon, numberOfCards=1){
        let losingReward = this.state.losingReward || {};
        let rewardsList = [];
        if(rewardWon){
            rewardsList.push(rewardWon)
        }
        while(rewardsList.length < numberOfCards){
            losingReward.revealImage = losingReward.revealImage || scratchMaskImage || "";
            rewardsList.push(losingReward)
        }
        rewardsList.sort(function() { return 0.5 - Math.random() });
        this.setState({
            scratchState: 1,
            scratcherList: rewardsList,
            loading: false
        })
    }

    moveUpArrayLength(currentLocation){
      const newLocation = currentLocation + 1;
      this.setState({
        hideRevealButton:false,
        showNext: false,
        currentScratcherLocation: newLocation,
      })
    }

    changeMilesToMeters(milesToConvert){
      return milesToConvert*1609.344;
    }

    getScratchers(){
        this.setState({
            loading: true,
            letsPlayDisabled: true
        });
      const tenantVariables = this.props.tenantVariables;
      const stringConstants = this.props.stringConstants || {};
      if(tenantVariables.collectDistance && tenantVariables.acceptableDistance && tenantVariables.longitude && tenantVariables.latitude){
          const locationPermissions = this.props.appStorage.getItem('locationPermissions');
          if(locationPermissions === "false"){
              const locationPermissionsHeader = tenantVariables.locationPermissionsHeader || "Location Permissions Required";
              const locationPermissionsBody = tenantVariables.locationPermissionsBody || "We need your location in order to play! We use this information to make your experience better";
              this.setState({
                  loading: false,
                  letsPlayDisabled: false
              });
              confirmAlert({
                  title: locationPermissionsHeader,
                  message: locationPermissionsBody,
                  cancelLabel: "",
                  confirmLabel: stringConstants.OKTEXT,
                  variables: tenantVariables,
                  onConfirm: () => {
                      this.setState({
                          loading: true,
                      });
                      this.getLocationPermission(tenantVariables)
                  }
              })
          } else {
              this.getLocationPermission(tenantVariables)
          }
      } else {
          this.continueStartingGame();
      }
    }

    continueStartingGame(){
        const stringConstants = this.props.stringConstants || {};
        const tenantVariables = this.props.tenantVariables || {};
        const userResponse = {};
        const userEmail = this.state.userEmail;
        let base64EncodedEmail = btoa(userEmail);
        if(userEmail && userEmail.indexOf('@') === -1){
            base64EncodedEmail = userEmail;
        }
        userResponse['userId'] = base64EncodedEmail;
        const vm = this;
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/getScratchers`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/getScratchers`;
        }
        this.Auth.fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userResponse),
        }).then(value => {
            vm.setState({
                loading: false
            })
            console.log(value)
            if(!value || value.error){
                confirmAlert({
                    variables: tenantVariables,
                    title: stringConstants.UNKNOWNERRORTITLE,
                    message: stringConstants.SOMETHINGWENTWRONGTEXT,
                    confirmLabel: stringConstants.OKTEXT
                });
                this.setState({
                    letsPlayDisabled: false,
                    loading: false
                })
            } else {
                if(value.result === "user_already_played"){
                    let anotherChanceToWinMessage = stringConstants.COMEBACKNEXTTIMETEXT;
                    let alreadyPlayedHeader = stringConstants.ALREADYPLAYEDHEADER;
                    confirmAlert({
                        variables: tenantVariables,
                        title: alreadyPlayedHeader,
                        message: anotherChanceToWinMessage,
                        cancelLabel: "",
                        confirmLabel: stringConstants.OKTEXT
                    });
                    this.setState({
                        letsPlayDisabled: false,
                        loading: false
                    })
                } else if(value.result === "no_active_game") {
                    let gameClosedTitle = tenantVariables.gameClosedHeader || stringConstants.GAMECLOSEDTEXT;
                    let gameClosedMessage = tenantVariables.gameClosedMessage || stringConstants.GAMENOTACTIVETEXT;
                    if(this.state.currentGame && this.state.currentGame.startTime){
                        const dateMilliSeconds = new Date(this.state.currentGame.startTime).getTime();
                        const dateNowMilliseconds = new Date().getTime();
                        if(dateNowMilliseconds < dateMilliSeconds){
                            gameClosedMessage += convertTimeStampToHumanReadable(dateMilliSeconds);
                        }
                    }
                    confirmAlert({
                        variables: tenantVariables,
                        title: gameClosedTitle,
                        message: gameClosedMessage,
                        cancelLabel: "",
                        confirmLabel: stringConstants.OKTEXT,
                    });
                    this.setState({
                        letsPlayDisabled: false,
                        loading: false
                    })
                } else if(value.result === "player_limit_hit") {
                    let gameClosedTitle = tenantVariables.noPrizesLeftHeader || stringConstants.NOPRIZESLEFTHEADER;
                    let gameClosedMessage = tenantVariables.noPrizesLeftBody || stringConstants.NOPRIZESLEFTBODY;
                    confirmAlert({
                        variables: tenantVariables,
                        title: gameClosedTitle,
                        message: gameClosedMessage,
                        cancelLabel: "",
                        confirmLabel: stringConstants.OKTEXT,
                    });
                    this.setState({
                        letsPlayDisabled: false,
                        loading: false
                    })
                } else if(value.result === "no_rewards_left"){
                    vm.populateWithTickets(null, value.numberOfCards);
                } else if(value.result === "no_user_data") {
                    confirmAlert({
                        variables: tenantVariables,
                        title: stringConstants.UNKNOWNERRORTITLE,
                        message: stringConstants.SOMETHINGWENTWRONGTEXT,
                        confirmLabel: stringConstants.OKTEXT
                    });
                    this.setState({
                        letsPlayDisabled: false,
                        loading: false
                    })
                } else if(typeof value.result === "object"){
                    vm.populateWithTickets(value.result, value.numberOfCards);
                }
            }
        })
    }

    download() {
        if(this.state.downloadedFile){
            fileDownload(this.state.downloadedFile, "reward.jpg")
            return
        }
        this.setState({
            downloadingImage: true
        })
        const vm = this;
        this.Auth.requestToDownload(this.state.gameId).then(res => {
            this.setState({
                downloadingImage: false,
            })
            if(res && res.apiStatus === "winning_reward_downloaded"){
                Axios.get(res.entity, {
                    responseType: 'blob',
                }).then(res => {
                    fileDownload(res.data, "reward.jpg")
                    vm.setState({
                        downloadingImage:false,
                        downloadedFile: res.data
                    })
                });
                this.setState({
                    scratchState:2,
                    downloadModal:false,
                    startConfetti: false
                })
            }
        })
    }

    logout(){
        this.props.appStorage.removeItem('userEmail');
        this.props.appStorage.removeItem('verifiedAge');
        this.props.appStorage.removeItem('birthday');
        this.props.appStorage.removeItem('locationPermissions');
        this.props.appStorage.removeItem('id_token');
        this.props.appStorage.removeItem('tempToken');
        this.setState({
            userEmail:false
        })
    }

    getLocationPermission(gotTheme){
      const stringConstants = this.props.stringConstants || {};
      const tenantVariables = this.props.tenantVariables || {};
      let locationErrorTitle = stringConstants.LOCATIONERRORHEADER;
      this.props.getLocation(gotTheme, stringConstants).then(locationObject => {
          const distance_meters = locationObject.distance;
          const lat = locationObject.lat;
          const long = locationObject.long;
        this.props.appStorage.setItem('locationPermissions', "true");
        const allowed_distance = this.changeMilesToMeters(gotTheme.acceptableDistance || 100); //In miles
        if(!gotTheme.canPlayOutside && distance_meters <= allowed_distance){
          this.continueStartingGame();
        } else if(gotTheme.canPlayOutside && distance_meters > allowed_distance) {
            this.continueStartingGame();
        } else if(gotTheme.locationCheckFailedCanPlay === true) {
            this.continueStartingGame();
        } else {
          this.setState({
            modal:false,
            loading:false,
            letsPlayDisabled: false
          });
        let locationErrorMessage = stringConstants.TOOFARFROMGAMEAREATOPART;
        if(gotTheme.canPlayOutside){
            locationErrorMessage = stringConstants.TOOCLOSETOGAMEAREATOPLAY;
        }
        if(gotTheme.notAcceptableLocationMessage){
            locationErrorMessage = gotTheme.notAcceptableLocationMessage;
        }
        let notAcceptableLocationHeader = stringConstants.LOCATIONERRORHEADER;
        if(gotTheme.notAcceptableLocationHeader){
            notAcceptableLocationHeader = gotTheme.notAcceptableLocationHeader;
        }
          confirmAlert({
              title: notAcceptableLocationHeader,
              message: locationErrorMessage,
              cancelLabel: "",
              variables: tenantVariables,
              confirmLabel: stringConstants.OKTEXT
          });
        }
      }, error => {
        this.setState({
            modal:false,
            loading:false,
            letsPlayDisabled: false
        });
        this.props.appStorage.setItem('locationPermissions', "false");
        if(typeof error != "string"){
          error = error.message
        }
        confirmAlert({
            title: locationErrorTitle,
            message: error,
            cancelLabel: "",
            variables: tenantVariables,
            confirmLabel: stringConstants.OKTEXT
        });
      })
    }

    showNextArrow(){
        const location = this.state.currentScratcherLocation;
        let winningTicketShown = false;
        if(this.state.scratcherList[location].isWinning){
            winningTicketShown = true
        }
        const tenantVariables = this.props.tenantVariables || {};
        const stringConstants = this.props.stringConstants || {};
        let losingHeader = tenantVariables.losingHeader;
        let losingMessage = tenantVariables.losingMessage;
        if(losingHeader === "null" || losingHeader === "undefined" || !losingHeader){
            losingHeader = "😭 SORRY! 😭"
        }
        if(losingMessage === "null" || losingMessage === "undefined" || !losingMessage){
            losingMessage = "You didn't win anything but come back next game and try again!"
        }
        if(this.state.scratcherList.length === location + 1 || winningTicketShown){
            if(winningTicketShown){
                this.setState({
                    hideRevealButton: true,
                    startConfetti: true
                }, () => {
                    // if(tenantVariables.collectEmail && validateEmail(vm.state.value)){
                    this.sendWinningEmailAddress(true);
                    // } else {
                    //     setTimeout(function () {
                    //         if(tenantVariables.authRequired){
                    //             vm.setState({
                    //                 showNext:false,
                    //                 downloadModal:true,
                    //             }, () => {
                    //                 let modalBackdrop = document.getElementsByClassName("modal-backdrop")[0];
                    //                 modalBackdrop.style.display = "none";
                    //                 let maxWidthAllowed = 300;
                    //                 const bodyWidth = Math.max(document.body.offsetWidth, document.body.clientWidth, document.body.scrollWidth);
                    //                 if(bodyWidth > 0 && bodyWidth < 300){
                    //                     maxWidthAllowed = bodyWidth;
                    //                 }
                    //                 const scratcherCard = vm.state.scratcherList[location];
                    //                 let c = document.createElement("canvas");
                    //                 let cf = document.createElement("canvas")
                    //                 let ctxf = cf.getContext('2d');
                    //                 let ctx = c.getContext('2d');
                    //                 let img1 = new Image();
                    //                 img1.onload= function() {
                    //                     ctx.drawImage(img1, 0, 0, maxWidthAllowed, maxWidthAllowed);
                    //                     ctxf.drawImage(img1, 0, 0, maxWidthAllowed, maxWidthAllowed);
                    //                 };
                    //                 img1.src = scratcherCard.rewardTemplate.emailImage;
                    //                 ctx.font = "25px Oswald";
                    //                 let textHeight = vm.wrapText(ctx, scratcherCard.rewardTemplate.description, maxWidthAllowed/2, 325, maxWidthAllowed, 25)
                    //                 let height = textHeight+325;
                    //                 if(scratcherCard.redeemCode){
                    //                     vm.fitTextOnCanvas(scratcherCard.redeemCode,"Oswald",textHeight+350, ctx, maxWidthAllowed);
                    //                     height = textHeight+375;
                    //                 }
                    //                 cf.style.backgroundColor = "white";
                    //                 cf.height = height;
                    //                 ctxf.textAlign = "center";
                    //                 ctxf.textBaseline = "middle";
                    //                 ctxf.fillStyle = "black";
                    //                 ctxf.font = "25px Oswald";
                    //                 vm.wrapText(ctxf, scratcherCard.rewardTemplate.description, maxWidthAllowed/2, 325, maxWidthAllowed, 25)
                    //                 if(scratcherCard.redeemCode){
                    //                     vm.fitTextOnCanvas(scratcherCard.redeemCode,"Oswald",textHeight+350, ctxf, maxWidthAllowed);
                    //                 }
                    //                 document.getElementById("downloadModalId").appendChild(cf);
                    //             })
                    //         } else if(vm.props.isMlbTeam) {
                    //             vm.sendWinningEmailAddress(true)
                    //         } else {
                    //             vm.setState({
                    //                 showNext:false,
                    //                 modal:true,
                    //             }, () => {
                    //                 let modalBackdrop = document.getElementsByClassName("modal-backdrop")[0];
                    //                 modalBackdrop.style.display = "none";
                    //             })
                    //         }
                    //     }, 3000);
                    // }
                })
            } else {
                confirmAlert({
                    variables: tenantVariables,
                    title: losingHeader,
                    message: losingMessage,
                    confirmLabel: "",
                    cancelLabel: stringConstants.OKTEXT
                });
                this.setState({
                  scratchState:2,
                })
            }
        } else {
            this.setState({
                hideRevealButton:true,
                showNext:true
            })
        }
    }

    toggle() {
      var vm = this;
      vm.setState({
        modal: !vm.state.modal,
        startConfetti: false,
        areYouSureModal: !vm.state.areYouSureModal,
      });
      // confirmAlert({
      //   title: 'ARE YOU SURE?',
      //   message: "Once you give up your prize you can't get it back. It's gone forever!",
      //   confirmLabel: "GIVE UP PRIZE",
      //   cancelLabel: 'BACK',
      //   onConfirm: () => {
      //     if(vm.state.scratchState === 1){
      //       vm.setState({
      //         scratchState:2,
      //       });
      //     }
      //   },
      //   onCancel: () => {
      //     vm.setState({
      //       modal: true,
      //     }, () => {
      //       var modalBackdrop = document.getElementsByClassName("modal-backdrop")[0]
      //       modalBackdrop.style.display = "none";
      //     })
      //   }
      // })
      // this.props.changeConfirmAlertStyles();
    }

    areYouSureToggle(){
      const vm = this;
      if(vm.state.scratchState === 1){
        vm.setState({
            areYouSureModal: !vm.state.areYouSureModal,
            scratchState:2,
            downloadModal: false,
            startConfetti: false
        });
      } else {
        vm.setState({
          areYouSureModal: !vm.state.areYouSureModal,
          modal: !vm.state.modal,
        }, () => {
          const modalBackdrop = document.getElementsByClassName("modal-backdrop")[0];
          modalBackdrop.style.display = "none";
        });
      }
    }

    sendPrizeInfoToggle(){
      const vm = this;
      vm.setState({
        sendPrizeInfoModal: !vm.state.sendPrizeInfoModal,
        modal: !vm.state.modal
      })
    }

    toggleSupport(){
        this.setState({
            modalSupport: !this.state.modalSupport
        });
    }

    toggleInfoAndRules(){
        this.setState({
            modalInfoAndRules: !this.state.modalInfoAndRules,
            modal: this.props.isMlbTeam
        });
    }

    handleChange(event) {
      this.setState({value: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        const email = this.state.value;
        const tenantVariables = this.props.tenantVariables || {};
        const stringConstants = this.props.stringConstants || {};
        const errorInputTitle = stringConstants.INPUTERRORTITLE;
        const errorEmailInputMessage = stringConstants.EMAILCANTBEBLANK;
        if(!email){
          this.setState({
            modal:false,
            startConfetti: false
          });
          confirmAlert({
              variables: tenantVariables,
              title: errorInputTitle,
              message: errorEmailInputMessage,
              cancelLabel: "",
              confirmLabel: stringConstants.OKTEXT,
              onConfirm: () => {
                this.setState({
                  modal:true
                })
              }
          });
          return;
        }
        if(!validateEmail(email)){
          this.setState({
            modal:false,
            startConfetti: false
          });
          confirmAlert({
              variables: tenantVariables,
              title: errorInputTitle,
              message: stringConstants.NOTANEMAILERROR,
              cancelLabel: "",
              confirmLabel: stringConstants.OKTEXT,
              onConfirm: () => {
                this.setState({
                  modal:true
                })
              }
          });
          return;
        }
        this.setState({
          modal: false,
          startConfetti: false,
          sendPrizeInfoModal: true
        })
      }

    sendWinningEmailAddress(skipLoading){
        const tenantVariables = this.props.tenantVariables;
        const stringConstants = this.props.stringConstants || {};
        const isMlb = process.env.REACT_APP_IS_MLB_TEAM === "true";
        const currentGame = this.state.currentGame || {};
        this.setState({
            loading: !skipLoading,
            sendPrizeInfoModal: false,
        });


        let tag = process.env.REACT_APP_FIREBASE_PROJECT_ID;
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/releasePrizes`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/releasePrizes`;
            tag = "";
        }
        console.log(currentGame, currentGame.id)
        this.Auth.fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({email: this.state.userEmail, isMlb: isMlb, mlbId: process.env.REACT_APP_MLB_TEAM_ID, language: this.state.language, gameId: currentGame.id, tag: tag}),
        }).then(value => {
            console.log(value)
            this.setState({
                loading:false
            })
            if(!value || value.error){
                confirmAlert({
                    variables: tenantVariables,
                    title: stringConstants.UNKNOWNERRORTITLE,
                    message: stringConstants.SOMETHINGWENTWRONGTEXT,
                    confirmLabel: stringConstants.OKTEXT
                });
            } else {
                let confirmationMessage = stringConstants.CHECKYOURINBOXFORPRIZE;
                let emailSentConfirmationTitle = stringConstants.EMAILSENT;
                let winningMessageImage = tenantVariables.winningMessageImage || "";
                if(tenantVariables.winningMessage){
                    confirmationMessage = tenantVariables.winningMessage;
                }
                if(tenantVariables.winningHeader){
                    emailSentConfirmationTitle = tenantVariables.winningHeader;
                }
                if (isMlb) {
                    emailSentConfirmationTitle = tenantVariables.winningHeader || "You Won!"
                    confirmationMessage = tenantVariables.winningMessage || "Check your MLB app inbox for your prize and thanks for playing!"
                }
                confirmAlert({
                    messageImage: winningMessageImage,
                    variables: tenantVariables,
                    title: emailSentConfirmationTitle,
                    message: confirmationMessage,
                    confirmLabel: '',
                    cancelLabel: '👍',
                    onCancel: () => {
                        this.setState({
                            sendPrizeInfoModal: false,
                            startConfetti: false,
                            loading: false,
                            scratchState: 2
                        });
                    }
                })
            }
        })
    }

    disablePullToRefresh(){
      let prevent = false;
      const element = '#root';
      document.querySelector(element).addEventListener('touchstart', function(e){
        if (e.touches.length !== 1) { return; }

        const scrollY = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop;
        prevent = (scrollY === 0);
      });

      document.querySelector(element).addEventListener('touchmove', function(e){
        if (prevent) {
          prevent = false;
          e.preventDefault();
        }
      });
    }

    renderStateOne(primaryColor, secondaryColor){
      const { isVisible } = this.state;
      const currentScratcherLocation = this.state.currentScratcherLocation;
      const tenantVariables = this.props.tenantVariables || {};
      const stringConstants = this.props.stringConstants || {};
      document.body.classList.add("lock-background");
      this.disablePullToRefresh();
      const scratchTopImage = tenantVariables.scratcherImage || scratchMaskImage;
      const nextTextVariable = stringConstants.NEXTTEXT;
      const settings = {
            width: 280,
            height: 380,
            image: scratchTopImage,
            finishPercent: 75,
            onComplete: () => this.showNextArrow()
        };
      return(
        <div className="scratch-container-home">
          {
            this.state.scratcherList.map(function(item,index){
              return(
                <div className="scratch-image-container" key={index}>
                    { index === currentScratcherLocation &&
                        <PoseGroup>
                            {isVisible && [
                                <ScratchContainer key={index} pose={isVisible ? 'visible' : 'hidden'} className="scratch-image" id="scratch-image">
                                    <ScratchCard accessibilityClicked={this.state['accessibilityClicked' + index]} {...settings}>
                                        <div className="cover-image-container">
                                            <img src={item.revealImage} onLoad={this.handleImageLoaded.bind(this)} onError={this.handleImageErrored.bind(this)} alt={item.isWinning && this.state.hideRevealButton ? item.rewardName:""} role={item.isWinning && this.state.hideRevealButton && "alert"} className="cover-image-control"/>
                                        </div>
                                    </ScratchCard>
                                    {tenantVariables.accessibilityOn && !this.state.hideRevealButton &&
                                    <div style={{textAlign: "center", marginTop: 10}}>
                                        <button className="btn btn-scratcher" style={{backgroundColor: primaryColor, color: secondaryColor}} onClick={()=> this.setState({['accessibilityClicked' + index]: true}) }>Can't Scratch? Click Here</button>
                                    </div>
                                    }
                                </ScratchContainer>
                            ]}
                        </PoseGroup>
                    }
                </div>
              )
            }, this)
          }
          <div className="button-item-container">
            <button className="btn btn-scratcher" style={{display: this.state.showNext ? 'block' : 'none', backgroundColor: primaryColor, color: secondaryColor }} onClick={this.moveUpArrayLength.bind(this, currentScratcherLocation)}>{nextTextVariable}</button>
          </div>
        </div>
      )
    }

    renderStateTwo(primaryColor, secondaryColor){
      const tenantVariables = this.props.tenantVariables || {};
      window.history.pushState("",document.title,window.location.pathname);
      const stringConstants = this.props.stringConstants || {};
      const thank_you_header = tenantVariables.thankYouHeader || "Thanks for playing!";
      const thank_you_message = tenantVariables.thankYouMessage || "";
      const homeTextVariable = stringConstants.HOMETEXT;
      document.body.classList.remove("lock-background");
      return(
        <div className="flex-content-container-home">
          <div className="thanks-container-home">
            <div className="hero-text-container" style={{backgroundColor: "white", padding:20, borderRadius:20, boxShadow: "3px 3px 7px rgba(0,0,0,0.3)"}}>
              <div className="thankYouContainer">
                <h1 className="thankYouHeader" style={{color: "black"}}>{thank_you_header}</h1>
                <span className="thankYouBodyContent" style={{color: "black"}} dangerouslySetInnerHTML={{ __html: thank_you_message}}/>
                <div className="button-item-container">
                  <button className="btn btn-scratcher" style={{backgroundColor: primaryColor, color: secondaryColor}} onClick={()=> window.location.reload(true)}>{homeTextVariable}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    renderStateZero(primaryColor, secondaryColor) {
      const tenantVariables = this.props.tenantVariables || {};
      const stringConstants = this.props.stringConstants || {};
      const mainImage = tenantVariables.frontLogoImage || "";
      document.body.classList.remove("lock-background");
      const letsPlayButtonText = stringConstants.LETSPLAYTEXT;
      return (
        <div className="flex-content-container-home">
          <Box pose={this.state.isVisible ? 'visible' : 'hidden'} className="intro-container-home" >
            <div className="hero-text-container">
              <img src={mainImage} onLoad={this.handleImageLoaded.bind(this)} onError={this.handleImageErrored.bind(this)} className="main-hero-image" alt={tenantVariables.pageTitle || ""}/>
            </div>
            <div className="button-item-container">
              <button className="btn btn-scratcher" style = {{backgroundColor: primaryColor, color: secondaryColor}}  onClick={() => {this.getScratchers()}} disabled={this.state.letsPlayDisabled}>{letsPlayButtonText}</button>
            </div>
          </Box>
        </div>
      )
    }

    render() {
      const tenantVariables = this.props.tenantVariables || {};
      const tenantRules = this.props.tenantRules || {};
      const stringConstants = this.props.stringConstants || {};
      const link = tenantRules.rulesAndRegsLink;
      const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";
      const topLeft = tenantVariables.topLeftImage || "";
      const primaryColor = tenantVariables.primaryColor || '#000';
      const secondaryColor = tenantVariables.secondaryColor || '#fff';
      const verifiedAge = this.props.checkForAgeGate(tenantVariables);
      const mlbPrivacyPolicyLink = tenantVariables.mlbPrivacyPolicyLink || "https://www.mlb.com/app/ballpark/official-information/privacy-policy";
      const supportHeader = tenantRules.supportHeader || "NEED SUPPORT?";
      const supportText = tenantRules.supportText || "Email customerservice@website.mlb.com with your issue and we'll be in touch!";
      const rulesPopUpHeader = tenantRules.rulesPopUpHeader || "Rules & Regs";
      const rulesPopUpText = tenantRules.rulesPopUpText || "";
      let buttonLogOutColor = primaryColor;
      let rulesInAppButtonText = "Rules & Regs";
      if(!this.state.userEmail){
          let nextUrl = 'login';
          return <Navigate to={nextUrl} />
      } else if(!tenantVariables.doNotCollectEmail && !isMlbApp && !validateEmail(this.state.userEmail)){
          this.logout()
      } else if((tenantVariables.doNotCollectEmail || isMlbApp) && validateEmail(this.state.userEmail)){
          this.logout()
      }
      if(!verifiedAge){
        return (
            <Navigate to={"/age_gate"} />
        )
      }
      document.body.classList.remove("lock-background");
      let renderMainScreenState = this.renderStateZero(primaryColor, secondaryColor);
      let winningHeader = tenantVariables.winningHeader;
      let winningMessage = tenantVariables.winningMessageText;
      let htmlDetected = false;
      if(/<[a-z][\s\S]*>/i.test(winningMessage)){
        htmlDetected = true
      }
      if(winningHeader === "null" || winningHeader === "undefined" || !winningHeader){
        winningHeader = "🎉 WINNER! 🎉"
      }
      if(winningMessage === "null" || winningMessage === "undefined" || !winningMessage){
        winningMessage = "You've won some awesome prizes. Enter your email below & we'll send them your way!"
      }
      if(this.state.scratchState === 1){
        renderMainScreenState = this.renderStateOne(primaryColor, secondaryColor)
      } else if(this.state.scratchState === 2){
        renderMainScreenState = this.renderStateTwo(primaryColor, secondaryColor)
      }
      // const cardToShow = this.state.currentScratcherLocation;
      // let rewardWon;
      // let winningCode;
      // let winningPrizeImage;
      // let description;
      // if(this.state.scratcherList.length > 0){
      //     if(this.state.scratcherList[cardToShow] && this.state.scratcherList[cardToShow].isWinning){
      //         rewardWon = this.state.scratcherList[cardToShow];
      //         // winningCode = rewardWon.redeemCode;
      //         // winningPrizeImage = rewardWon.rewardTemplate.emailImage;
      //         // description = rewardWon.rewardTemplate.description;
      //     }
      // }
        let authRequired = tenantVariables.authRequired;
        if(authRequired === "false" || authRequired === "null"){
            authRequired = false;
        }
        return(
          <div className="flex-container-home">
              {this.state.startConfetti &&
                <Confetti numberOfPieces={400}/>
              }
            <div className="loader" style={{display: this.state.loading ? 'block' : 'none' }}>
                <PulseLoader color={primaryColor}/>
              {/*<hr style={{background: primaryColor}}/><hr style={{background: primaryColor}}/><hr style={{background: primaryColor}}/><hr style={{background: primaryColor}}/>*/}
            </div>
            <Box pose={this.state.isVisible ? 'visible' : 'hidden'} className="flex-header-home">
                  {this.props.isMlbTeam ?
                      <div style={{width: "25%", height: 38, color: buttonLogOutColor, border: "solid 1px " + buttonLogOutColor, borderRadius: 5, flexGrow:1, maxWidth: "25%", fontFamily: "Oswald"}}>
                          <button className="btn" style={{color: buttonLogOutColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => { this.toggleSupport() }}>
                              <Textfit mode="single" max={16}>
                                  Support
                              </Textfit>
                          </button>
                      </div>
                      :
                      <div className="header-left">
                        <img src={topLeft} className="topLeftLogo" alt={tenantVariables.tenantName || ""}/>
                      </div>
                  }
                {isMlbApp ?
                    <>
                        <div style={{ width: "25%", color: buttonLogOutColor, height: 38, border: "solid 1px " + buttonLogOutColor, borderRadius: 5, flexGrow:1, maxWidth: "25%", fontFamily: "Oswald"}}>
                            <button className="btn" style={{color: buttonLogOutColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => {window.open("https://www.mlb.com/app/ballpark/official-information/terms-of-use", "_blank")}}>
                                <Textfit mode="single" max={16}>
                                    MLB TOU
                                </Textfit>
                            </button>
                        </div>
                        <div style={{ width: "25%", height: 38, color: buttonLogOutColor, border: "solid 1px " + buttonLogOutColor, borderRadius: 5, flexGrow:1, maxWidth: "25%", fontFamily: "Oswald"}}>
                            <button className="btn" style={{color: buttonLogOutColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => { window.open(mlbPrivacyPolicyLink, "_blank") }}>
                                <Textfit mode="single" max={16}>
                                    MLB Privacy Policy
                                </Textfit>
                            </button>
                        </div>
                        <div style={{width: "25%", height: 38, color: buttonLogOutColor, border: "solid 1px " + buttonLogOutColor, borderRadius: 5, flexGrow:1, maxWidth: "25%", fontFamily: "Oswald"}}>
                            <button className="btn" style={{color: buttonLogOutColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => { !link?this.toggleInfoAndRules():window.open(link, '_blank') }}>
                                <Textfit mode="single" max={16}>
                                    {rulesInAppButtonText}
                                </Textfit>
                            </button>
                        </div>
                    </>
                    :
                    <div className="header-right" style={{visibility: authRequired? 'hidden':''}}>
                        <button className="btn btn-logout" onClick={() => { this.logout() }} style={{backgroundColor: '#F6F5ED', color: buttonLogOutColor, borderColor: buttonLogOutColor, boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.LOGOUTTEXT}</button>
                    </div>
                }
            </Box>
            {renderMainScreenState}
            <Modal isOpen={this.state.modal && !isMlbApp} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <ModalHeader toggle={this.toggle} style={{color: secondaryColor}}>{winningHeader}</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                      <form onSubmit={this.handleSubmit} id="create-email-form">
                        <div className="form-group">
                          <label style={{fontSize:'18px', display: htmlDetected ? 'none' : ''}} htmlFor="email">{winningMessage}</label>
                          <label style={{fontSize: '18px', display: htmlDetected ? '' : 'none'}} htmlFor="email" dangerouslySetInnerHTML={{__html: winningMessage}}/>
                          <input id="email" name="email" type="email" className="form-control" style={{textAlign:"center"}} value={this.state.value} onChange={this.handleChange} placeholder="example@email.com" />
                        </div>
                        <button className="btn btn-default btn-admin" id="submitButton" style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'15px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.SUBMITBUTTONENTER}</button>
                      </form>
                      <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggle() }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'15px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.DONTWANTPRIZETEXT}</button>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={this.state.areYouSureModal} toggle={this.areYouSureToggle} style={{width: '90%'}} id="myModalAreYouSure">
              <ModalHeader toggle={this.areYouSureToggle} style={{color: secondaryColor}}>{stringConstants.AREYOUSURETEXT}</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                        <div className="form-group">
                          <label style={{fontSize:'18px'}}>{stringConstants.ONCEPRIZEGONEGIVEUPTEXT}</label>
                        </div>
                      <button className="btn btn-default btn-admin" onClick={() => { this.areYouSureToggle() }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'15px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.GIVEUPPRIZE}</button>
                      <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.state.downloadModal ? this.setState({areYouSureModal:false}) : this.toggle() }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'15px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.JUSTKIDDINGTAKEMEBACK}</button>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={this.state.sendPrizeInfoModal} toggle={this.sendPrizeInfoToggle} style={{width: '90%'}} id="myModalAreYouSure">
              <ModalHeader toggle={this.sendPrizeInfoToggle} style={{color: secondaryColor}}>{stringConstants.SENDPRIZEINFOTEXT}</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                        <div className="form-group">
                          <label style={{fontSize:'1.4rem'}}>{this.state.value}</label>
                        </div>
                      <button className="btn btn-default btn-admin" onClick={() => { this.sendWinningEmailAddress() }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'10px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.SENDTEXT}</button>
                      <button className="btn btn-default btn-admin" onClick={() => { this.sendPrizeInfoToggle() }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'15px', fontSize: "0.9rem", height:'auto', minWidth: "40%", whiteSpace: "normal", lineHeight: "normal",width: "auto", boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.OOPSWRONGEMAIL}</button>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={this.state.downloadModal} id="myModal">
                <center id="downloadModalId">
                    {/*<img style={{padding:10, margin:"auto"}} width={300} height={300} src={winningPrizeImage} alt=""/>*/}
                    {/*<p>{description}</p>*/}
                    {/*<p style={{display: winningCode ? "" : "none"}}>{winningCode}</p>*/}
                    {/*<div style={{marginTop:5}}>*/}
                    {/*    {!this.state.downloadingImage ?*/}
                    {/*        <button type="button" onClick={()=>this.download()} className="btn btn-next" style={{backgroundColor: tenantVariables.primaryColor || "black", color: tenantVariables.secondaryColor || "white"}}>Download</button>*/}
                    {/*        :*/}
                    {/*        <button type="button" className="btn btn-next" style={{backgroundColor: tenantVariables.primaryColor || "black", color: tenantVariables.secondaryColor || "white"}}>*/}
                    {/*            <i className="fa fa-spinner fa-spin" aria-hidden="true"/> Loading*/}
                    {/*        </button>*/}
                    {/*    }*/}
                    {/*</div>*/}
                    {/*<div style={{marginTop:5, marginBottom: 5}}>*/}
                    {/*    <button type="button" className="btn btn-next" onClick={()=> this.setState({areYouSureModal:true})} style={{backgroundColor: tenantVariables.primaryColor || "black", color: tenantVariables.secondaryColor || "white"}}>Dismiss</button>*/}
                    {/*</div>*/}
                </center>
            </Modal>
              <Modal isOpen={this.state.modalSupport} style={{width: '90%'}} id="rulesModal">
                  <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {supportHeader}
                    </span>
                  </ModalHeader>
                  <ModalBody>
                      <center className="container-out">
                          <div className="question-box question-form">
                              <p style={{width:"100%",overflow:"scroll",fontWeight:100, fontSize:14, marginBottom: 0}} dangerouslySetInnerHTML={{ __html:supportText}}/>
                              <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleSupport(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', fontSize:'1.3 rem'}}>Dismiss</button>
                          </div>
                      </center>
                  </ModalBody>
              </Modal>
              <Modal isOpen={this.state.modalInfoAndRules} toggle={this.toggleInfoAndRules} style={{width: '90%'}} id="infoAndRulesModal">
                  <ModalHeader toggle={this.toggleInfoAndRules} style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {rulesPopUpHeader}
                    </span>
                  </ModalHeader>
                  <ModalBody>
                      <div className="container-out">
                          <div className="question-box question-form">
                              <p dangerouslySetInnerHTML={{ __html:rulesPopUpText}}/>
                              {!this.props.isMlbTeam &&
                                  <button className="btn btn-default btn-admin" onClick={() => {  window.open(link, '_blank') }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.RULESTEXT}</button>
                              }
                              <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleInfoAndRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.CLOSETEXT}</button>
                          </div>
                      </div>
                  </ModalBody>
              </Modal>
          </div>
     )
    }
}

export default Home;
